import { Component } from "@angular/core";
import { IModalWindowOptions } from "src/app/shared/components/modal-window/modal-options.interface";
import { ModalWindowService } from "src/app/shared/components/modal-window/modal-window.service";

@Component({
    selector: "ge-delete-driver-detail-modal",
    templateUrl: "./delete-driver-detail-modal.component.html",
})
export class DeleteDriverDetailModalComponent {
    constructor(private modalWindowService: ModalWindowService) {}

    modalWindowOptions: IModalWindowOptions = {
        title: "driverDetails.modals.deleteModalTitle",
        buttons: [
            {
                label: "driverDetails.modals.deleteModalDeleteButton",
                theme: "cranberry",
                click: this.close.bind(this, true),
            },
        ],
    };

    close(value) {
        this.modalWindowService.close(value);
    }
}
