import { DriverDetailsActivities } from "./interfaces";

export const DriverActivities = [
    {
        label: "driverDetails.activitiesTypes.schoolTripOrder",
        value: "SchoolTripOrder",
    },
    {
        label: "driverDetails.activitiesTypes.aToBOrder",
        value: "AtoBOrder",
    },
    {
        label: "driverDetails.activitiesTypes.groupTripsOrder",
        value: "GroupTripsOrder",
    },
    {
        label: "driverDetails.activitiesTypes.multidayTripsOrder",
        value: "MultidayTripsOrder",
    },
    {
        label: "driverDetails.activitiesTypes.collection",
        value: "Collection",
    },
    {
        label: "driverDetails.activitiesTypes.return",
        value: "Return",
    },
    {
        label: "driverDetails.activitiesTypes.domestic",
        value: "Domestic",
    },
    {
        label: "driverDetails.activitiesTypes.transvisionOrderTrip",
        value: "TransvisionContractOrder",
    },
    {
        label: "driverDetails.activitiesTypes.internationalShuttle",
        value: "InternationalShuttle",
    },
    {
        label: "driverDetails.activitiesTypes.individualTripOrder",
        value: "IndividualTripOrder",
    },
    {
        label: "driverDetails.activitiesTypes.busTrip",
        value: "BusManualTrip",
    },
    {
        label: "driverDetails.activitiesTypes.carTrip",
        value: "CarManualTrip",
    },
    {
        label: "driverDetails.activitiesTypes.sickday",
        value: "Sickday",
    },
    {
        label: "driverDetails.activitiesTypes.freeActivity",
        value: "FreeActivity",
    },
];

export const leaveActivities = {
    Daysoff: {
        label: "driverDetails.activitiesTypes.daysoff",
        value: "Daysoff",
    },
    EmployerDayOff: {
        label: "driverDetails.activitiesTypes.employerDayOff",
        value: "EmployerDayOff",
    },
    ExtraordinaryDayOff: {
        label: "driverDetails.activitiesTypes.extraDaysOff",
        value: "ExtraordinaryDayOff",
    },
    CompensationDayOff: {
        label: "driverDetails.activitiesTypes.compensationDayOff",
        value: "CompensationDayOff",
    },
    SavedDaysOff: {
        label: "driverDetails.activitiesTypes.savedDaysOff",
        value: "SavedDaysOff",
    },
    "Wacht/werktijd": {
        label: "driverDetails.activitiesTypes.standby",
        value: "Wacht/werktijd",
    },
    Onderbreking: {
        label: "driverDetails.activitiesTypes.onderbreking",
        value: "Onderbreking",
    },
    "Bus bij huis": {
        label: "driverDetails.activitiesTypes.busBijHuis",
        value: "Bus bij huis",
    },
};

// temporary constant year for filtering on driver hours page/statements
export const START_FILTERING_YEAR = 2021;

export const EMPTY_HOURS_DISPLAY_PLACEHOLDER = "0.00";

export const NOT_TRIP_ACTIVITIES = [
    DriverDetailsActivities.Restday,
    DriverDetailsActivities.Daysoff,
    DriverDetailsActivities.Sickday,
    DriverDetailsActivities.NationalHoliday,
    DriverDetailsActivities.EmployerDayOff,
    DriverDetailsActivities.ExtraordinaryDayOff,
];
