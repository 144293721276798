/* src/app/driver-hours/driver-details/row-driver-detail-modal/row-driver-detail-modal.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
::ng-deep .modal-dialog {
  min-width: 700px;
}
.fixed-cell-width::ng-deep .ge-form-group {
  width: 82px;
}
.mr-10 {
  margin-right: 10px;
}
.date-invalid ::ng-deep input {
  border: 1px solid #98002c;
}
.row-driver-detail-modal__outer-container {
  padding: 32px;
  box-sizing: border-box;
}
.row-driver-detail-modal__header-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row-driver-detail-modal__title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #0b162d;
}
.row-driver-detail-modal__close-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background-color: #ffffff;
}
.row-driver-detail-modal__close-button-label {
  font-size: 11px;
  line-height: 15px;
  color: #828282;
  margin-bottom: 5px;
}
.row-driver-detail-modal__form {
  margin-top: 40px;
}
.row-driver-detail-modal__row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.row-driver-detail-modal__cell {
  margin-right: 10px;
}
.row-driver-detail-modal__cell-label {
  display: flex;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #0b162d;
}
.row-driver-detail-modal__cell-label--spaced {
  justify-content: space-between;
}
.row-driver-detail-modal__cell-label--optional {
  color: #828282;
  font-weight: 400;
}
.row-driver-detail-modal__cell-double-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row-driver-detail-modal__cell-double-inputs span {
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
}
.row-driver-detail-modal__cell--medium {
  width: 155px;
}
.row-driver-detail-modal__cell--big {
  width: 300px;
}
.row-driver-detail-modal__cell--full-sized {
  width: 100%;
}
.row-driver-detail-modal__cell--flex-end {
  display: flex;
  justify-content: flex-end;
}
.row-driver-detail-modal__overlapping-table {
  display: flex;
  flex-direction: column;
}
.row-driver-detail-modal__overlapping-row {
  display: grid;
  grid-template-columns: 90px 200px 100px auto;
  margin-bottom: 5px;
}
.row-driver-detail-modal__overlapping-heading {
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: #828282;
}
.row-driver-detail-modal__overlapping-text {
  font-size: 14px;
  line-height: 20px;
  color: #0b162d;
}
.row-driver-detail-modal__cancel-button {
  width: 125px;
  margin-right: 17px;
}
.row-driver-detail-modal__add-button {
  width: 140px;
}
/*# sourceMappingURL=row-driver-detail-modal.component.css.map */
