import { DriverDetailsActivities } from "../../shared/interfaces";

export const TRIP_ACTIVITIES: DriverDetailsActivities[] = [
    DriverDetailsActivities.AtoBOrder,
    DriverDetailsActivities.SchoolTripOrder,
    DriverDetailsActivities.GroupTripsOrder,
    DriverDetailsActivities.MultidayTripsOrder,
    DriverDetailsActivities.Domestic,
    DriverDetailsActivities.InternationalShuttle,
    DriverDetailsActivities.IndividualTripOrder,
    DriverDetailsActivities.DrentheCollegeContractOrder,
    DriverDetailsActivities.GroevenbeekContractOrder,
    DriverDetailsActivities.SunwebContractOrder,
    DriverDetailsActivities.TransvisionContractOrder,
    DriverDetailsActivities.ViabusContractOrder,
    DriverDetailsActivities.VincentVanGoghContractOrder,
    DriverDetailsActivities.Collection,
    DriverDetailsActivities.Return,
    DriverDetailsActivities.BusManualTrip,
    DriverDetailsActivities.CarManualTrip,
    DriverDetailsActivities.ViabusTitle,
    DriverDetailsActivities.SunwebTitle,
];

export const ACTIVITIES_WITHOUT_VALUES: DriverDetailsActivities[] = [
    DriverDetailsActivities.Restday,
    DriverDetailsActivities.Daysoff,
    DriverDetailsActivities.Sickday,
    DriverDetailsActivities.NationalHoliday,
    DriverDetailsActivities.EmployerDayOff,
    DriverDetailsActivities.ExtraordinaryDayOff,
    DriverDetailsActivities.CompensationDayOff,
    DriverDetailsActivities.SavedDaysOff,
    DriverDetailsActivities["Bus bij huis"],
    DriverDetailsActivities.Onderbreking,
    DriverDetailsActivities["Wacht/werktijd"],
];

export const ACTIVITIES_WITH_DEFAULT_TIMES: DriverDetailsActivities[] = [
    DriverDetailsActivities.Restday,
    DriverDetailsActivities.Daysoff,
    DriverDetailsActivities.Sickday,
    DriverDetailsActivities.NationalHoliday,
    DriverDetailsActivities.EmployerDayOff,
    DriverDetailsActivities.ExtraordinaryDayOff,
    DriverDetailsActivities.CompensationDayOff,
    DriverDetailsActivities.SavedDaysOff,
];

export const NON_WORKING_ACTIVITIES: DriverDetailsActivities[] = [
    DriverDetailsActivities.NationalHoliday,
    DriverDetailsActivities.Restday,
    DriverDetailsActivities["Bus bij huis"],
    DriverDetailsActivities.Onderbreking,
    DriverDetailsActivities["Wacht/werktijd"],
];
