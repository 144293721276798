/* src/app/driver-hours/driver-details/delete-driver-detail-modal/delete-driver-detail-modal.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.delete-driver-modal-window::ng-deep .modal-header-container {
  align-items: flex-start;
}
.delete-driver-modal-window::ng-deep .modal-footer {
  flex-flow: row-reverse;
}
.delete-driver-modal-window::ng-deep .modal-footer {
  justify-content: flex-start !important;
}
.delete-driver-modal-window::ng-deep .modal-footer ge-button {
  margin: 0 20px 0 0;
}
.delete-driver-modal-window::ng-deep .modal-footer ge-button .button {
  width: 150px;
}
.delete-driver-modal-window::ng-deep .modal-footer > ge-button .button {
  background: #98002c;
  color: #ffffff;
  width: 127px;
  font-weight: 700;
  font-size: 14px;
}
.delete-driver-modal-text {
  font-size: 16px;
  line-height: 24px;
  color: #0b162d;
  padding-left: 13px;
}
/*# sourceMappingURL=delete-driver-detail-modal.component.css.map */
