import { Component, OnInit } from "@angular/core";
import { IModalWindowOptions } from "src/app/shared/components/modal-window/modal-options.interface";
import { ModalWindowService } from "src/app/shared/components/modal-window/modal-window.service";
import { DriverHoursService } from "../../services/driver-hours.service";

@Component({
    selector: "ge-driver-detail-close-page-modal",
    templateUrl: "./driver-detail-close-page-modal.component.html",
    styleUrls: [
        "../delete-driver-detail-modal/delete-driver-detail-modal.component.scss",
        "./driver-detail-close-page-modal.component.scss",
    ],
})
export class DriverDetailClosePageModalComponent implements OnInit {
    modalWindowOptions: IModalWindowOptions = {
        title: "driverDetails.modals.closeWithoutSaveTitle",
        buttons: [
            {
                label: "driverDetails.modals.saveAndCloseButton",
                theme: "cranberry",
                click: this.saveAndClose.bind(this),
            },
            {
                label: "driverDetails.modals.closeWithoutSaveButton",
                theme: "default",
                click: this.close.bind(this),
            },
        ],
    };

    constructor(
        private modalWindowService: ModalWindowService,
        private driverHoursService: DriverHoursService,
    ) {}

    public ngOnInit(): void {}

    public close(): void {
        this.modalWindowService.navigateAwaySelection$.next(true);
        this.modalWindowService.close(true);
    }

    public saveAndClose(): void {
        this.driverHoursService.closeWithSave$.next(true);
    }
}
