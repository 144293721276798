import { AbstractControl, ValidationErrors } from "@angular/forms";

export class CustomValidators {
    static datesValidation() {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value == null) {
                return null;
            }
            const startDate = control.get("start").value;
            const endDate = control.get("end").value;
            if (
                startDate &&
                endDate &&
                new Date(startDate) >= new Date(endDate)
            ) {
                return {
                    error: "The start date exceeds the end date",
                    params: {
                        startDate: startDate,
                        endDate: endDate,
                    },
                };
            }
            return null;
        };
    }
}
