<div class="row-driver-detail-modal__outer-container">
    <div class="row-driver-detail-modal__inner-container">
        <div class="row-driver-detail-modal__header-block">
            <h2
                class="row-driver-detail-modal__title"
                data-translate-me
                [translateMe]="'driverDetails.modals.addRowModal.newRow'"
            >
                {{ modalTitle | i18n }}
            </h2>
            <button
                class="row-driver-detail-modal__close-button"
                (click)="closeModal()"
            >
                <span class="row-driver-detail-modal__close-button-label"
                    >Esc</span
                >
                <img src="/assets/imgs/close.png" width="20" height="20" />
            </button>
        </div>
        <form [formGroup]="form" class="row-driver-detail-modal__form">
            <div class="row-driver-detail-modal__row">
                <div
                    class="row-driver-detail-modal__cell row-driver-detail-modal__cell--medium"
                >
                    <span
                        class="row-driver-detail-modal__cell-label"
                        data-translate-me
                        [translateMe]="'driverDetails.modals.addRowModal.date'"
                    >
                        {{ "driverDetails.modals.addRowModal.date" | i18n }}
                    </span>
                    <ge-datepicker
                        [ngClass]="{
                            'date-invalid':
                                form.get('date').invalid &&
                                form.get('date').touched,
                        }"
                        [placeholder]="
                            'driverDetails.modals.addRowModal.chooseDate' | i18n
                        "
                        (dateChange)="onDateChange($event)"
                        [value]="datepickerDate"
                        [minDate]="minDate"
                        [maxDate]="maxDate"
                        [disabled]="isNetHoursEditable"
                    ></ge-datepicker>
                </div>
                <div
                    class="row-driver-detail-modal__cell row-driver-detail-modal__cell--big"
                >
                    <span
                        class="row-driver-detail-modal__cell-label"
                        data-translate-me
                        [translateMe]="
                            'driverDetails.modals.addRowModal.activity'
                        "
                    >
                        {{ "driverDetails.modals.addRowModal.activity" | i18n }}
                    </span>
                    <ge-select
                        formControlName="activity"
                        data-translate-me
                        [translateMe]="
                            'driverDetails.modals.addRowModal.chooseActivity'
                        "
                        [placeholder]="
                            'driverDetails.modals.addRowModal.chooseActivity'
                                | i18n
                        "
                    >
                        <option
                            *ngFor="let option of activityOptions"
                            [value]="option.value"
                        >
                            {{ option.label | i18n }}
                        </option>
                    </ge-select>
                </div>
                <div class="row-driver-detail-modal__cell">
                    <span
                        class="row-driver-detail-modal__cell-label"
                        data-translate-me
                        [translateMe]="
                            'driverDetails.modals.addRowModal.statement'
                        "
                    >
                        {{ "driverDetails.modals.addRowModal.times" | i18n }}
                    </span>
                    <div class="row-driver-detail-modal__cell-double-inputs">
                        <ge-masked-time-input
                            #startTimeInputEl
                            [isInvalid]="
                                form.get('start').invalid &&
                                form.get('start').touched
                            "
                            [time]="startDayTime"
                            placeholder=""
                            (onTimeChanged)="onTimeChange($event, true)"
                        ></ge-masked-time-input>
                        <span>-</span>
                        <ge-masked-time-input
                            #endTimeInputEl
                            [isInvalid]="
                                form.get('end').invalid &&
                                form.get('end').touched
                            "
                            [time]="endDayTime"
                            placeholder=""
                            (onTimeChanged)="onTimeChange($event, false)"
                        ></ge-masked-time-input>
                    </div>
                </div>
            </div>
            <div class="row-driver-detail-modal__row" *ngIf="showAllFormFields">
                <div
                    class="row-driver-detail-modal__cell row-driver-detail-modal__cell--full-sized"
                >
                    <span
                        class="row-driver-detail-modal__cell-label row-driver-detail-modal__cell-label--spaced"
                    >
                        <span
                            data-translate-me
                            [translateMe]="
                                'driverDetails.modals.addRowModal.description'
                            "
                        >
                            {{
                                "driverDetails.modals.addRowModal.description"
                                    | i18n
                            }}
                        </span>
                    </span>
                    <ge-input
                        formControlName="description"
                        [placeholder]="
                            'driverDetails.modals.addRowModal.descriptionText'
                                | i18n
                        "
                    >
                    </ge-input>
                </div>
            </div>
            <div
                class="row-driver-detail-modal__row"
                *ngIf="overlappingLines.length"
            >
                <div class="row-driver-detail-modal__cell">
                    <span
                        class="row-driver-detail-modal__cell-label"
                        data-translate-me
                        [translateMe]="
                            'driverDetails.modals.addRowModal.statement'
                        "
                    >
                        {{
                            "driverDetails.modals.addRowModal.overlappingTitle"
                                | i18n
                        }}
                    </span>
                    <div class="row-driver-detail-modal__overlapping-table">
                        <div class="row-driver-detail-modal__overlapping-row">
                            <div
                                class="row-driver-detail-modal__overlapping-heading"
                                data-translate-me
                                [translateMe]="
                                    'driverDetails.modals.addRowModal.date'
                                "
                            >
                                {{
                                    "driverDetails.modals.addRowModal.date"
                                        | i18n
                                }}
                            </div>
                            <div
                                class="row-driver-detail-modal__overlapping-heading"
                                data-translate-me
                                [translateMe]="
                                    'driverDetails.modals.addRowModal.activity'
                                "
                            >
                                {{
                                    "driverDetails.modals.addRowModal.activity"
                                        | i18n
                                }}
                            </div>
                            <div
                                class="row-driver-detail-modal__overlapping-heading"
                                data-translate-me
                                [translateMe]="
                                    'driverDetails.modals.addRowModal.statement'
                                "
                            >
                                {{
                                    "driverDetails.modals.addRowModal.times"
                                        | i18n
                                }}
                            </div>
                            <div
                                class="row-driver-detail-modal__overlapping-heading"
                                data-translate-me
                                [translateMe]="
                                    'driverDetails.modals.addRowModal.description'
                                "
                            >
                                {{
                                    "driverDetails.modals.addRowModal.description"
                                        | i18n
                                }}
                            </div>
                        </div>
                        <div
                            class="row-driver-detail-modal__overlapping-row"
                            *ngFor="let overlap of overlappingLines"
                        >
                            <div
                                class="row-driver-detail-modal__overlapping-text"
                            >
                                {{ overlap.date | date: dateFormat }}
                            </div>
                            <div
                                class="row-driver-detail-modal__overlapping-text"
                            >
                                {{
                                    activitiesDictionary[overlap.activity]
                                        | i18n
                                }}
                            </div>
                            <div
                                class="row-driver-detail-modal__overlapping-text"
                            >
                                <ng-container
                                    *ngIf="
                                        overlap.activity !==
                                        driverDetailsActivities.Restday
                                    "
                                >
                                    <span>
                                        {{ overlap.start | date: timeFormat }}
                                    </span>
                                    -
                                    <span>
                                        {{ overlap.end | date: timeFormat }}
                                    </span>
                                </ng-container>
                            </div>
                            <div
                                class="row-driver-detail-modal__overlapping-text"
                            >
                                {{ overlap.description }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="showAllFormFields; then allFields; else someFields">

            </ng-container>


            <ng-template #allFields>
                <div class="row-driver-detail-modal__row" *ngIf="showAllFormFields">
                    <div
                        class="row-driver-detail-modal__cell row-driver-detail-modal__cell--full-sized"
                    >
                    <span class="row-driver-detail-modal__cell-label">
                        <span
                            data-translate-me
                            [translateMe]="
                                'driverDetails.modals.addRowModal.grossHours'
                            "
                        >
                            {{
                                "driverDetails.modals.addRowModal.grossHours"
                                    | i18n
                            }}
                        </span>
                    </span>
                        <ge-input
                            class="fixed-cell-width"
                            type="string"
                            formControlName="grossHours"
                            geToFixed
                            [placeholder]="'0.00'"
                        >
                        </ge-input>
                    </div>
                    <ng-container
                        *ngTemplateOutlet="netHoursControl"
                    >

                    </ng-container>
                    <div
                        class="row-driver-detail-modal__cell row-driver-detail-modal__cell--full-sized"
                    >
                    <span class="row-driver-detail-modal__cell-label">
                        <span
                            data-translate-me
                            [translateMe]="
                                'driverDetails.modals.addRowModal.ort'
                            "
                        >
                            {{ "driverDetails.modals.addRowModal.ort" | i18n }}
                        </span>
                    </span>
                        <ge-input
                            class="fixed-cell-width"
                            type="string"
                            formControlName="oRT"
                            [placeholder]="'000.00'"
                        >
                        </ge-input>
                    </div>
                    <div
                        class="row-driver-detail-modal__cell row-driver-detail-modal__cell--full-sized"
                    >
                    <span class="row-driver-detail-modal__cell-label">
                        <span
                            data-translate-me
                            [translateMe]="
                                'driverDetails.modals.addRowModal.obt'
                            "
                        >
                            {{ "driverDetails.modals.addRowModal.obt" | i18n }}
                        </span>
                    </span>
                        <div class="row-driver-detail-modal__cell-double-inputs">
                            <ge-input
                                class="fixed-cell-width mr-10"
                                type="string"
                                formControlName="oBTHigh"
                                [placeholder]="
                                'driverDetails.modals.addRowModal.high' | i18n
                            "
                            >
                            </ge-input>
                            <ge-input
                                class="fixed-cell-width"
                                type="string"
                                formControlName="oBTLow"
                                [placeholder]="
                                'driverDetails.modals.addRowModal.low' | i18n
                            "
                            >
                            </ge-input>
                        </div>
                    </div>
                    <div
                        class="row-driver-detail-modal__cell row-driver-detail-modal__cell--full-sized"
                    >
                    <span class="row-driver-detail-modal__cell-label">
                        <span
                            data-translate-me
                            [translateMe]="
                                'driverDetails.modals.addRowModal.expences'
                            "
                        >
                            {{
                                "driverDetails.modals.addRowModal.expences"
                                    | i18n
                            }}
                        </span>
                    </span>
                        <div class="row-driver-detail-modal__cell-double-inputs">
                            <ge-input
                                class="fixed-cell-width mr-10"
                                type="string"
                                formControlName="expencesGross"
                                [placeholder]="
                                'driverDetails.modals.addRowModal.gross' | i18n
                            "
                            >
                            </ge-input>
                            <ge-input
                                class="fixed-cell-width"
                                type="string"
                                formControlName="expencesNet"
                                [placeholder]="
                                'driverDetails.modals.addRowModal.net' | i18n
                            "
                            >
                            </ge-input>
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template #someFields>
                <ng-container *ngIf="isNetHoursEditable">
                    <ng-container
                        *ngTemplateOutlet="netHoursControl"
                    >
                    </ng-container>
                </ng-container>
            </ng-template>

            <ng-template #netHoursControl>
                <div
                    class="row-driver-detail-modal__cell row-driver-detail-modal__cell--full-sized"
                >
                    <span class="row-driver-detail-modal__cell-label">
                        <span
                            data-translate-me
                            [translateMe]="
                                'driverDetails.modals.addRowModal.netHours'
                            "
                        >
                            {{
                                "driverDetails.modals.addRowModal.netHours"
                                    | i18n
                            }}
                        </span>
                    </span>
                    <ge-input
                        class="fixed-cell-width"
                        type="string"
                        formControlName="netHours"
                        geToFixed
                        [placeholder]="'0.00'"
                    >
                    </ge-input>
                </div>
            </ng-template>

            <div class="row-driver-detail-modal__row">
                <div
                    class="row-driver-detail-modal__cell row-driver-detail-modal__cell--full-sized row-driver-detail-modal__cell--flex-end"
                >
                    <ge-button
                        [click]="closeModal.bind(this)"
                        class="row-driver-detail-modal__cancel-button"
                        theme="default"
                        data-translate-me
                        [translateMe]="
                            'driverDetails.modals.addRowModal.cancel'
                        "
                    >
                        {{ "driverDetails.modals.addRowModal.cancel" | i18n }}
                    </ge-button>
                    <ge-button
                        [click]="submit.bind(this)"
                        [disabled]="isSubmitDisabled"
                        class="row-driver-detail-modal__add-button"
                        theme="cranberry"
                        data-translate-me
                        [translateMe]="
                            'driverDetails.modals.addRowModal.addRow'
                        "
                    >
                        {{
                            (data.trip
                                ? "driverDetails.buttons.save"
                                : "driverDetails.modals.addRowModal.addRow"
                            ) | i18n
                        }}
                    </ge-button>
                </div>
            </div>
        </form>
    </div>
</div>
