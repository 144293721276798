import { Component, OnInit, ViewChild } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import moment from "moment";
import { ModalWindowService } from "src/app/shared/components/modal-window/modal-window.service";
import {
    DriverActivitiesLines,
    IDriverHoursLineResponse,
} from "../../shared/driver-hours.model";
import {
    ACTIVITIES_TYPES_DICTIONARY,
    DriverDetailsActivities,
    DriverDetailsNotTransferActivities,
} from "../../shared/interfaces";
import { DriverActivities, leaveActivities } from "../../shared/const";
import {
    debounceTime,
    distinctUntilChanged,
    take,
    takeUntil,
    pairwise,
    startWith,
} from "rxjs/operators";
import { isEqual } from "lodash";
import { CustomValidators } from "../../shared/custom-validators";
import { MaskedTimeInputComponent } from "../../../shared/components/ge-masked-time-input/masked-time-input.component";
import { DestructibleController } from "../../../common/classes/destructible.controller";
import {
    ACTIVITIES_WITH_DEFAULT_TIMES,
    ACTIVITIES_WITHOUT_VALUES,
    NON_WORKING_ACTIVITIES,
    TRIP_ACTIVITIES
} from "./consts";
enum RequiredFormFields {
    Date = "date",
    Activity = "activity",
    Start = "start",
    End = "end",
    GrossHours = "grossHours",
    NetHours = "netHours",
    Description = "description",
}

@Component({
    selector: "ge-row-driver-detail-modal",
    templateUrl: "./row-driver-detail-modal.component.html",
    styleUrl: "./row-driver-detail-modal.component.scss",
})
export class RowDriverDetailModalComponent
    extends DestructibleController
    implements OnInit
{
    public activityOptions = [...DriverActivities];

    public activitiesWithoutValues: DriverDetailsActivities[] =
        ACTIVITIES_WITHOUT_VALUES;

    public activitiesWithDefaultTimes: DriverDetailsActivities[] =
        ACTIVITIES_WITH_DEFAULT_TIMES;

    public editableNetHoursValue: DriverDetailsActivities[] = [
        DriverDetailsActivities.Daysoff,
        DriverDetailsActivities.Sickday,
        DriverDetailsActivities.EmployerDayOff,
        DriverDetailsActivities.ExtraordinaryDayOff,
        DriverDetailsActivities.CompensationDayOff,
        DriverDetailsActivities.SavedDaysOff,
    ];

    public needAdditionalFields: string[] = ["FreeActivity"];

    public activitiesDictionary = ACTIVITIES_TYPES_DICTIONARY;
    public readonly dateFormat: string = "dd-MM-yyyy";
    public readonly timeFormat: string = "HH:mm";

    public form: UntypedFormGroup;
    public data: {
        trip: IDriverHoursLineResponse;
        statementDate: Date;
        driverTrips: DriverActivitiesLines[];
    };

    public datepickerDate: Date;
    public endDayTime: Date = null;
    public startDayTime: Date = null;

    public minDate: Date;
    public maxDate: Date;

    public overlappingLines: IDriverHoursLineResponse[] = [];

    public notTransferableActivity = DriverDetailsNotTransferActivities;
    public driverDetailsActivities = DriverDetailsActivities;
    public isNetHoursEditable = false;
    private _isSubmitDisabled = false;
    private _showAllFormFields = false;
    private tripActivities: DriverDetailsActivities[] = TRIP_ACTIVITIES;

    @ViewChild("startTimeInputEl", { static: true })
    startTimeInputEl: MaskedTimeInputComponent;
    @ViewChild("endTimeInputEl", { static: true })
    endTimeInputEl: MaskedTimeInputComponent;
    constructor(
        private fb: UntypedFormBuilder,
        private modalWindowService: ModalWindowService,
    ) {
        super();
    }

    get isEditRowMode(): boolean {
        return !!this.data.trip;
    }
    get modalTitle(): string {
        return this.data.trip
            ? "driverDetails.modals.addRowModal.editRow"
            : "driverDetails.modals.addRowModal.newRow";
    }
    get showAllFormFields(): boolean {
        return this._showAllFormFields;
    }
    set showAllFormFields(value: boolean) {
        this._showAllFormFields = value;
    }

    get isSubmitDisabled(): boolean {
        return this._isSubmitDisabled || !!this.overlappingLines.length;
    }
    set isSubmitDisabled(value: boolean) {
        this._isSubmitDisabled = value;
    }

    public ngOnInit(): void {
        this.setDate();
        if (this.isEditRowMode) {
            this.getEndDayTime();
            this.getStartDayTime();
        } else {
            this.startTimeInputEl.disabled = true;
            this.endTimeInputEl.disabled = true;
        }
        this.initForm();
        this.handleFormChanges();
        this.getOverlappingLines();
        this.toggleFieldsVisibility();
        this.initSubscription();
    }

    public initForm(): void {
        this.form = this.fb.group(
            {
                date: [this.datepickerDate],
                activity: [{ value: null, disabled: true }],
                start: [this.startDayTime],
                end: [this.endDayTime],
                grossHours: [null],
                netHours: [null],
                description: [{ value: null, disabled: this.isEditRowMode }],
                oRT: null,
                oBTHigh: null,
                oBTLow: null,
                expencesGross: null,
                expencesNet: null,
            },
            { validators: CustomValidators.datesValidation() },
        );

        if (this.data && this.data.trip) {
            const { trip } = this.data;

            if (leaveActivities[trip.activity]) {
                this.activityOptions.push(leaveActivities[trip.activity]);
            }

            this.form.patchValue({
                ...trip,
                grossHours: trip.grossHours ? trip.grossHours.toFixed(2) : null,
                netHours: trip.netHours ? trip.netHours.toFixed(2) : null,
                oRT: trip.oRT ? trip.oRT.toFixed(2) : null,
                oBTHigh: trip.oBTHigh ? trip.oBTHigh.toFixed(2) : null,
                oBTLow: trip.oBTLow ? trip.oBTLow.toFixed(2) : null,
                expencesGross: trip.expencesGross
                    ? trip.expencesGross.toFixed(2)
                    : null,
                expencesNet: trip.expencesNet
                    ? trip.expencesNet.toFixed(2)
                    : null,
            });
            this.form
                .get("activity")
                .setValue(DriverDetailsActivities[trip.activity]);
        }
    }
    /**
     * Updates the disabled state of multiple form controls based on the edit mode.
     *
     * @param controlStates - An array of strings representing the names of form controls to update.
     */
    private adjustControlsForEditMode(controlStates: string[]): void {
        controlStates.forEach((controlState) => {
            const control = this.form.get(controlState);
            if (control) {
                if (this.isEditRowMode && control.enabled) {
                    control.disable({ emitEvent: false });
                } else if (!this.isEditRowMode && control.disabled) {
                    control.enable({ emitEvent: false });
                }
            } else {
                console.warn(
                    `Control '${controlState}' not found in the form.`,
                );
            }
        });
    }

    public getOverlappingLines(): void {
        this.overlappingLines = [];

        if (
            !this.data.driverTrips ||
            !this.data.driverTrips.length ||
            !(this.startDayTime && this.endDayTime)
        ) {
            return;
        }

        const lines: IDriverHoursLineResponse[] = this.data.driverTrips.flatMap(
            (trip) => trip.lines,
        );

        lines.forEach((line: IDriverHoursLineResponse, i) => {
            if (this.data.trip && this.data.trip.id === line.id) {
                return;
            }

            if (
                NON_WORKING_ACTIVITIES.includes(line.activity)
            ) {
                return;
            }
            if (
                moment(this.startDayTime).isBetween(line.start, line.end) ||
                moment(this.endDayTime).isBetween(line.start, line.end) ||
                moment(line.start).isBetween(
                    this.startDayTime,
                    this.endDayTime,
                ) ||
                moment(line.end).isBetween(
                    this.startDayTime,
                    this.endDayTime,
                ) ||
                moment(this.startDayTime).isSame(line.start) ||
                moment(this.endDayTime).isSame(line.end)
            ) {
                this.overlappingLines.push(line);
            }
        });
    }

    public onDateChange(e): void {
        this.form.get("date").patchValue(e);

        const start = this.form.get("start").value;
        const end = this.form.get("end").value;

        if (start) {
            const startHours = moment(start).get("hours");
            const startMinutes = moment(start).get("minutes");

            this.startDayTime = moment(e)
                .startOf("day")
                .add(startHours, "hours")
                .add(startMinutes, "minutes")
                .toDate();
            this.form.get("start").patchValue(this.startDayTime);
        }

        if (end) {
            const endHours = moment(end).get("hours");
            const endMinutes = moment(end).get("minutes");

            this.endDayTime = moment(e)
                .startOf("day")
                .add(endHours, "hours")
                .add(endMinutes, "minutes")
                .toDate();

            this.form.get("end").patchValue(this.endDayTime);
        }

        this.getOverlappingLines();
    }

    public onTimeChange(e: string, isStart: boolean): void {
        let currentDate = moment(this.form.get("date").value);
        const hours = e.split(":")[0];
        const minutes = e.split(":")[1];

        currentDate = currentDate
            .startOf("day")
            .add(hours, "hours")
            .add(minutes, "minutes");

        let start, end;

        if (isStart) {
            start = currentDate;
            end = this.form.get("end").value;

            this.form.get("date").patchValue(currentDate);
            this.form.get("start").patchValue(currentDate);
            this.startDayTime = currentDate.toDate();
        } else {
            start = this.form.get("start").value;
            end = currentDate;
        }

        if (start && end && new Date(start) >= new Date(end)) {
            this.endDayTime = null;
            this.endTimeInputEl.time = null;
            this.form.get("end").patchValue(null);
            return;
        }

        if (!isStart) {
            this.form.get("end").patchValue(currentDate);
            this.endDayTime = currentDate.toDate();
        }

        this.getOverlappingLines();
    }

    public setDate(): void {
        this.datepickerDate =
            (this.data && this.data.trip && new Date(this.data.trip.date)) ||
            moment(this.data.statementDate).startOf("day").toDate();
        this.minDate = moment(this.datepickerDate).startOf("month").toDate();
        this.maxDate = moment(this.datepickerDate).endOf("month").toDate();
    }

    public getStartDayTime(): void {
        const startTime = this.data && this.data.trip && this.data.trip.start;
        this.startDayTime = startTime
            ? new Date(startTime)
            : moment(this.datepickerDate).startOf("day").toDate();
    }

    public getEndDayTime(): void {
        const endTime = this.data && this.data.trip && this.data.trip.end;
        this.endDayTime = endTime
            ? new Date(endTime)
            : moment(this.datepickerDate).endOf("day").toDate();
    }

    public closeModal(): void {
        this.modalWindowService.close();
    }

    public markFormAsTouched(): void {
        for (const controllName in this.form.controls) {
            this.form.controls[controllName].markAsTouched();
        }
    }

    public submit(): void {
        this.markFormAsTouched();

        if (this.form.valid) {
            if (this.data && this.data.trip) {
                this.form.addControl(
                    "id",
                    new UntypedFormControl(this.data.trip.id),
                );
            }

            this.modalWindowService.close({
                ...this.form.value,
                grossHours: parseFloat(this.form.value.grossHours),
                netHours: parseFloat(this.form.value.netHours),
                oRT: parseFloat(this.form.value.oRT),
                oBTLow: parseFloat(this.form.value.oBTLow),
                oBTHigh: parseFloat(this.form.value.oBTHigh),
                expencesGross: parseFloat(this.form.value.expencesGross),
                expencesNet: parseFloat(this.form.value.expencesNet),
            });
        } else {
            console.log("Form is invalid");
        }
    }

    private setValidators(field: string): void {
        const control = this.form.get(field);
        control.setValidators(Validators.required);
    }

    private clearValidators(field: string): void {
        const control = this.form.get(field);
        control.clearValidators();
    }

    private updateControlValidity(field: string): void {
        const control = this.form.get(field);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }

    private initSubscription(): void {
        this.form
            .get("activity")
            .valueChanges.pipe(
                startWith(null),
                pairwise(),
                takeUntil(this.destroy$),
            )
            .subscribe(([prev, curr]) => {
                this.toggleFieldsVisibility(prev);

                if (
                    !this.isEditRowMode &&
                    (this.startTimeInputEl.disabled || this.endTimeInputEl.disabled)
                ) {
                    this.startTimeInputEl.disabled = false;
                    this.endTimeInputEl.disabled = false;
                }
            });

        this.form
            .get("date")
            .valueChanges.pipe(take(1), takeUntil(this.destroy$))
            .subscribe(() => {
                this.adjustControlsForEditMode(["activity"]);
            });
    }

    private toggleFieldsVisibility(
        prevActivity?: DriverDetailsActivities,
    ): void {
        const activity: DriverDetailsActivities =
            this.form.get("activity").value;
        this.isNetHoursEditable = this.editableNetHoursValue.includes(activity);
        const tripRequiredFields: RequiredFormFields[] = [
            RequiredFormFields.Date,
            RequiredFormFields.Activity,
            RequiredFormFields.Start,
            RequiredFormFields.End,
            RequiredFormFields.GrossHours,
            RequiredFormFields.NetHours,
            RequiredFormFields.Description,
        ];
        const activityRequiredFields: RequiredFormFields[] = [
            RequiredFormFields.Date,
            RequiredFormFields.Activity,
            RequiredFormFields.Start,
            RequiredFormFields.End,
        ];

        const isActivitySelected =
            this.activitiesWithoutValues.includes(activity);
        if (isActivitySelected) {
            this.showAllFormFields = false;
            tripRequiredFields.forEach((field) => {
                this.clearValidators(field);
                this.updateControlValidity(field);
            });
            activityRequiredFields.forEach((field) => {
                this.setValidators(field);
                this.updateControlValidity(field);
            });

            if (this.isNetHoursEditable) {
                this.setValidators(RequiredFormFields.NetHours);
                this.updateControlValidity(RequiredFormFields.NetHours);

                this.startTimeInputEl.disabled = true;
                this.endTimeInputEl.disabled = true;
            }
        } else if (activity) {
            this.showAllFormFields = true;
            tripRequiredFields.forEach((field) => {
                this.setValidators(field);
                this.updateControlValidity(field);
            });
        }

        if (activity) {
            this.adjustControlsForEditMode(["activity", "description"]);
        }
        this.form.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        this.setDefaultValues(prevActivity);
    }

    private setDefaultValues( prevActivity: DriverDetailsActivities): void {
        if (this.isEditRowMode) {
            return;
        }

        const activity: DriverDetailsActivities =
            this.form.get("activity").value;

        if (this.activitiesWithDefaultTimes.includes(activity)) {
            this.setDefaultTimes();
        }

        const isTripActivity = this.tripActivities.includes(activity);
        const isPrevNotTrip = !(
            prevActivity && this.tripActivities.includes(prevActivity)
        );

        if (isTripActivity && isPrevNotTrip) {
            this.clearTimeFields();
        }
    }

    private clearTimeFields(): void {
        this.form.get("start").setValue(null);
        this.form.get("end").setValue(null);
        this.startTimeInputEl.time = null;
        this.endTimeInputEl.time = null;
    }

    private setDefaultTimes(): void {
        const startOfDay = moment(this.form.get("date").value).startOf("day");
        const endOfDay = moment(this.form.get("date").value).endOf("day");

        this.form.get("start").setValue(startOfDay.toDate());
        this.form.get("end").setValue(endOfDay.toDate());
        this.startTimeInputEl.time = startOfDay.toDate();
        this.endTimeInputEl.time = endOfDay.toDate();
    }

    public allowSubmitAction(): void {
        this.isSubmitDisabled = false;
    }

    private handleFormChanges(): void {
        this.form.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
            )
            .subscribe(() => {
                this.allowSubmitAction();
            });
    }
}
